import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { gql } from 'graphql-request';
import React, { useEffect, useState } from 'react';
import { animated, config, useTransition } from 'react-spring';
import { axiosPost, cancelTokenSource } from '../utils/quickFetch';




const StyledRoot = styled(animated.div)(
    ({ theme }) => 
    css`
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.getContrastText(theme.palette.primary.main)};
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto 1fr;
        justify-items: center;
        gap: ${theme.spacing(1)};

        .MuiButton-outlined {
            margin: ${theme.spacing(3)};
            font: ${theme.typography.h4};
            color: ${theme.palette.getContrastText(theme.palette.primary.main)};
            border-color: ${theme.palette.getContrastText(theme.palette.primary.main)};

            :hover{
                border-color: ${theme.palette.getContrastText(theme.palette.primary.main)};
            }
        }

        > div:nth-of-type(2){
            display: grid;
            grid-template-columns: 1;
            grid-template-rows: 1;
            align-items: center;
            justify-items: center;

            * {
                text-align: center;
            }

            .MuiTypography-root:nth-of-type(1){
                font:${theme.typography.h5}
            }

            .MuiTypography-root:nth-of-type(2){
                font:${theme.typography.subtitle1}
            }
        }
    `
);

const GET_TOKEN = gql `
    mutation($qrCode: String!)
    {
        generateQrToken(qrCode: $qrCode)
    }
  `

const SUBMIT_QN = gql `
    mutation($data: String! $authToken: String!)
    {
        addQrRecord(data:$data, authToken: $authToken)
    }
  `

const Status = {
    SUBMITTING : 0,
    FAILED: 1,
    SUCCESS: 2
}

const Display = (props) => {
    const {status, result} = props
    const firstName = result && result.firstName? result.firstName : ''

    if(status === Status.SUBMITTING){
        return(
            <>
                <Typography>{'Submitting...'}</Typography>
                <Typography>{'Please Wait'}</Typography>
            </> 
        )        
    }else if(status === Status.FAILED){
        return(
            <>
                <Typography>{'Oops! Submission failed!'}</Typography>
                <Typography>{'Please resubmit'}</Typography>
                <Button variant='outlined'>Resubmit</Button>
            </> 
        )        
    }else if(status === Status.SUCCESS){
        return(
            <>
                <Typography>{`Thank you ${firstName}`}</Typography>
                <Typography>{'You have successfully registered'}</Typography>
            </> 
        )        
    }
}

export const Submit = (props) => {

    const {state, setState} = props;    

    const {clinic, page, result} = state

    const show = page===2;

    const [status, setStatus] = useState(null);

    const transitions = useTransition(show, {
        from: { opacity: 0.5, transform: 'translate(0%, 100%)'  },
        enter: { opacity: 1, transform: 'translate(0%, 0%)' },
        leave: { opacity: 0.5, transform: 'translate(0%, -100%)'  },
        reverse: show,
        config: {...config.stiff, clamp:true}
        // config: {
        //     tension: 210,
        //     friction: 20,
        //     clamp: true
        // }
    })
    
    const openQn = () => {
        setState({...state, page: 1})
    }

    const submitQuestionnaire = token => {
        setStatus(Status.SUBMITTING)

        const variables = { data : JSON.stringify(result), authToken: token }

        const query = SUBMIT_QN

        const data =  {query, variables }

        const onSuccess = data => {            
            let success = data.addQrRecord
            setStatus(Status.SUCCESS)
        }

        const onGraphqlErrors = (errors) => {
            setStatus(Status.FAILED)
        }

        
        axiosPost(data, {onGraphqlErrors, onSuccess}, cancelTokenSource.token)
    }

    const fetchToken = () => {
        setStatus(Status.SUBMITTING)

        const variables = { qrCode : clinic.qrCode }

        const query = GET_TOKEN

        const data =  {query, variables }

        const onSuccess = data => {            
            let token = data.generateQrToken

            if(token){
                submitQuestionnaire(token)
            }
        }

        const onGraphqlErrors = (errors) => {
            setStatus(Status.FAILED)
        }

        
        axiosPost(data, {onGraphqlErrors, onSuccess}, cancelTokenSource.token)
        
    }


    useEffect(()=>{
        if(page===2){
            fetchToken()
        }
    }, [page])
  
    return transitions(
        (styles, item) => item && 
        <StyledRoot style={styles}>
            <div/>
            <div>
                <div>
                    <Display status={status} result={result}/>
                </div>
            </div>         
        </StyledRoot>
    )


    
}