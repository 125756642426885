import { createTheme, StyledEngineProvider, ThemeProvider} from "@mui/material";
import { Root } from "./components/Root";
import {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@mui/material/colors";
// import { createTheme } from "@mui/system";

const App = () => {
    const theme = createTheme({
        palette: {
            mode: "light",
            primary: {
                main: teal[500],
            },
            secondary: {
                main: orange[500],
            },
        },
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Root />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
