import axios from "axios";
import { getBaseUrl } from "./apiUrls";
// import { getBaseUrl } from "../../utils/apiUrls";

class TokenRefresher {
    requestPromise = undefined

    setRequestPromise(promise){
        this.requestPromise = promise
    }

    getRequestPromise(){
        return this.requestPromise
    }

}


const tokensRefresher = new TokenRefresher();

tokensRefresher.setRequestPromise()


export const apiAxios = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true
})

apiAxios.interceptors.request.use(
    (config) => {
        // const credentials = getCredentials()

        // if (credentials && credentials.a_token) {
        //     config.headers["Authorization"] = "Bearer " + credentials.a_token;
        // }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
)

apiAxios.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null

    if (status === 401) {
        
        // return refreshToken().then(() => {
        //     // error.config.headers['Authorization'] = 'Bearer ' + getCredentials().a_token
        //     error.config.baseURL = undefined;
        //     return apiAxios.request(error.config);
        // });
    }

    return Promise.reject(error);
});


export const fireAppResetEvent = () => {
    const event = new Event('reset_app');
    window.dispatchEvent(event)
}