//########################################################
// Images Endpoint

export const IMAGES_ENDPOINT = 'https://d18q4j54gycib7.cloudfront.net'

export const S3_BUCKET = 'recordit-uploads'



//########################################################


export const getBaseUrl = ()=>{
    return "https://recordit-api.herokuapp.com/graphql"
}

export const getWsUrl = ()=>{
    return 'wss://recordit-api.herokuapp.com/ws'
}

export const getAuthUrl = ()=>{
    return "https://recordit-api.herokuapp.com/auth"
}

export const getImagesUrl = ()=>{
    return "https://recordit-api.herokuapp.com/images"
}

export const getAverageColorUrl = ()=>{
    return "https://recordit-api.herokuapp.com/extractColor"
}


//#######################################################################

// export const getBaseUrl = ()=>{
//     return "http://localhost:8080/graphql"
// }

// export const getWsUrl = ()=>{    
//     return "ws://localhost:8080/ws"
// }

// export const getAuthUrl = ()=>{
//     return "http://localhost:8080/auth"
// }

// export const getImagesUrl = ()=>{
//     return "http://localhost:8080/images"
// }


