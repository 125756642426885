import { gql } from 'graphql-request'
import React, { useEffect, useState } from 'react'
import { axiosPost, cancelTokenSource } from '../utils/quickFetch'
import { Landing } from './Landing'

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Questionnaire } from './Questionnaire';
import { Submit } from './Submit';



const StyledRoot = styled("div")(
    ({ theme }) => 
    css`
        background-color: ${theme.palette.background};
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: stretch;
        justify-items: stretch;
    `
);

const GET_CLINIC = gql `
    query($clinicId: String!)
    {
        getClinic(clinicId: $clinicId) {
            imageId
            clinicId
            clinicName
            physicalAddress
            postalAddress
            postalCode
            postalLocation
            phone
            email
            status
            website
            settings
            qrCode
            createdTimestamp
            modifiedTimestamp
            regAgreement
        }
    }
  `

export const Root = (props) => {

    const [state, setState] = useState({
        page: 0,
        clinic: {},
        consent: ''
    })

    const fetchClinic = (clinicId) => {

        

        const variables = { clinicId }

        const query = GET_CLINIC

        const data =  {query, variables }

        const onSuccess = data => {            
            let clinicData = data.getClinic

            const {regAgreement, ...clinic} = clinicData

            const rawConsent = JSON.parse(regAgreement)

            const consent = rawConsent.Version? rawConsent : null

            if(clinic){
                setState({...state, clinic, consent, errors: false})                 
            }else{
                setState({...state, errors: true})  
            }
        }

        const onGraphqlErrors = (errors) => {
        }

        
        axiosPost(data, {onGraphqlErrors, onSuccess}, cancelTokenSource.token)
    }


    useEffect(()=>{
        const parts = window.location.pathname.split("/")

        const params = {}
        window.location.search
            .replace(
                /[?&]+([^=&]+)=([^&]*)/gi, 
                (str, key, value)=> {
                    params[key] = value;
                }
            )

        const clinicRef = params['q'];

        // if(parts.length > 0){
        //     fetchClinic(parts[parts.length-1])
        // }

        if(parts.length > 0){
            fetchClinic(clinicRef)
        }
        
    }, [])

   
    return (
        <StyledRoot>
            <Landing state={state} setState={setState}/>
            <Questionnaire state={state} setState={setState}/>
            <Submit state={state} setState={setState}/>
        </StyledRoot>
    )

}