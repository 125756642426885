import axios from "axios"
import { apiAxios, fireAppResetEvent } from "./api_connector"

export const cancelTokenSource = axios.CancelToken.source()

export const axiosPost = (data, handlers, cancelToken ) => {
    const {onSuccess, onGraphqlErrors, onFinished} = (handlers? handlers: {})

    apiAxios.request({
        method: 'POST',
        data,
        cancelToken
    }).then(response=>{
        const {data, errors } =  response.data
        
        if(errors){
            if(onGraphqlErrors) onGraphqlErrors(errors)
            else handleErrors(errors)            
        }else {
            if(onSuccess) onSuccess(data)
        }

    }).catch(err=>{
        if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
        } else {
            handleHttpError(err)
        }        
    }).finally(()=>{
        if(onFinished)onFinished()
    })
}


const handleErrors = (errors) => {
    let errorMessage = errors[0].message.split(':')[1]
    if(errorMessage && errorMessage.toLowerCase().includes('access denied')){
        fireAppResetEvent()
    }        
}

const handleHttpError = (error) => {
    if(error.response){
        console.log(error.response.data)
    }else{
        console.log({message: "Error: No network connection"})
        
        // if(onError)onError([{message: "Error: No network connection"}]); 
    }  
}




