import React, { useEffect, useState} from 'react'

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import {useTransition, config, animated} from 'react-spring'



const StyledRoot = styled(animated.div)(
    ({ theme }) => 
    css`
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.getContrastText(theme.palette.primary.main)};
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto 1fr;
        justify-items: center;
        gap: ${theme.spacing(1)};

        > div:nth-of-type(1){
            display: grid;
            grid-template-columns: 1;
            grid-template-rows: 1;
            align-items: center;
            justify-items: center;

            * {
                text-align: center;
            }
        }

        > .MuiButton-outlined {
            margin: ${theme.spacing(3)};
            font: ${theme.typography.h4};
            color: ${theme.palette.getContrastText(theme.palette.primary.main)};
            border-color: ${theme.palette.getContrastText(theme.palette.primary.main)};

            :hover{
                border-color: ${theme.palette.getContrastText(theme.palette.primary.main)};
            }
        }
       
    `
);

export const Landing = (props) => {

    const {state, setState} = props;    

    const {clinic, page, errors} = state

    const show = page===0;

    const transitions = useTransition(show, {
        // from: { opacity: 0 },
        enter: { opacity: 1, transform: 'translate(0%, 0%)' },
        leave: { opacity: 0.5, transform: 'translate(0%, -100%)'  },
        reverse: show,
        config: {...config.stiff, clamp:true}
        // config: {
        //     tension: 210,
        //     friction: 20,
        //     clamp: true
        // }
    })
    
    const openQn = () => {
        setState({...state, page: 1})
    }

    return transitions(
        (styles, item) => item && 
        <StyledRoot style={styles}>
                <div>
                    {
                        errors?
                        <Typography variant='subtitle2'>Broken link, scan QR CODE to get the correct link</Typography>:
                        <div>
                            <Typography variant='h5'>{Boolean(clinic.clinicName) ? clinic.clinicName : 'Loading...'}</Typography>
                            <Typography variant='subtitle2'>{Boolean(clinic.clinicName) ? 'Customer Registration' : 'Please Wait'}</Typography>
                        </div>
                    }
                </div>
                {Boolean(clinic.clinicName) && <Button onClick={openQn} variant='outlined' disableElevation>START</Button>}
                <div />
        </StyledRoot>
    )

    // return (
    //     <StyledRoot>
    //         <div/>
    //         <Typography variant='h6'>{Boolean(clinic.clinicName) ? clinic.clinicName: 'Loading...'}</Typography>
    //         <Typography variant='subtitle2'>{Boolean(clinic.clinicName)? 'Welcome! Click start to begin customer registration' : 'Please Wait'}</Typography>
    //         {Boolean(clinic.clinicName) && <Button variant='outlined' disableElevation>START</Button>}
    //         <div/>
    //     </StyledRoot>
    // )

    
}