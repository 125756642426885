import { FormControl, FormHelperText, InputLabel, OutlinedInput, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

const validateDate = (text, options) => {
    const d = dayjs(text, 'YYYY-MM-DD', false)
    const {maxDate, minDate, setHelperText} = options
    if(d.isValid()){

        if(maxDate){
            const max = dayjs(maxDate)

            if(d.isAfter(max)){
                setHelperText(`Invalid Date ${d.format("YYYY-MM-DD")}, Maximum date allowed is ${max.format("YYYY-MM-DD")}`)
                return ''
            }
        }
        
        if(minDate){
            const min = dayjs(minDate)

            if(d.isBefore(min)){
                setHelperText(`Invalid Date ${d.format("YYYY-MM-DD")}, Minimum date allowed is ${min.format("YYYY-MM-DD")}`)
                return ''
            }
        }

        return d.format("YYYY-MM-DD")
                
    }else{
        return ''
    }
}

const DatePicker = (props) => {

    const {label, onChange, value, required, maxDate, minDate} = props;
    const [helperText, setHelperText] = useState('')


    const handleChange = e => {
        setHelperText('')
        let val = e.target.value.replace(/[^\d-]/g, ''); 

        
        const isAdded = val.length > value.length 

        if(isAdded){
            val = val.split('-').filter(part=>part.length).join('-')

            if(val.length === 6 && Number(val.charAt(val.length-1))>1){ //2->02
                val = insertAt(val, '0', val.length-1)
            }else if(val.length === 6 && Number(val.charAt(val.length-1))===1 && e.target.value.endsWith('-')){ //'yyyy-1-'->'yyyy-01-'
                val = insertAt(val, '0', val.length-1)
            }

            if(val.length === 7 && Number(val.charAt(val.length-2)) === 1 && Number(val.charAt(val.length-1)) > 2){ //19->12
                val = value.concat(2)
            }

            if(val.length === 9){ //2020-05-7 --> 2020-05-07
                const last = Number(val.charAt(val.length-1))

                const month = Number(val.split('-')[1]??1)

                const isFeb = month===2

                if(last > (isFeb? 2 : 3)){
                    val = insertAt(val, '0', val.length-1)
                }
                
            }
    
            if(val.length === 4 || val.length === 7){
                val = val+'-'
            }
    
            if(val.length >= 10){
                val = validateDate(val, {maxDate, minDate, setHelperText})
            }
        }else{

            val = val.split('-').filter(part=>part.length).join('-')

            if(value.startsWith(e.target.value)){
                val = value.endsWith('-')? value.substring(0, value.length - 2) : value.substring(0, value.length - 1);
            }else{
                val = ''
            }
        }

        onChange(val)
    }

    const handleBlur = e => {
        let val = e.target.value;
        val = validateDate(val, {maxDate, minDate, setHelperText})

        onChange(val)
    }

    return (
        <FormControl>
            <InputLabel error={Boolean(helperText)}>{`${label}${required?  ' *': ''}`}</InputLabel>

            <OutlinedInput 
                label={`${label}`} 
                required={Boolean(required)}
                value={value}
                type="tel"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(helperText)}
            />
            {
                Boolean(helperText) &&
                <FormHelperText error>{helperText}</FormHelperText>
            }
        </FormControl>
    )
    
};
export default DatePicker;