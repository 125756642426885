import React, { useEffect, useState } from 'react'

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Step, StepContent, StepLabel, Stepper, TextField, Toolbar, Typography } from '@mui/material';
import { useTransition, config, animated } from 'react-spring'
import DatePicker from './DatePicker';
import dayjs from 'dayjs';
import parse from 'html-react-parser'; 



const StyledRoot = styled(animated.div)(
    ({ theme }) =>
        css`
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        
       
    `
);


const StyledStepper = styled(Stepper)(
    ({ theme }) =>
        css`
        min-width: 40vw;
        margin: ${theme.spacing(1)} ${theme.spacing(3)};  

    `
);

const StyledForm = styled("form")(
    ({ theme }) =>
        css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 1fr);
        padding: ${theme.spacing(3)};
        padding-left: 5%;
        padding-right: 5%;
        gap: ${theme.spacing(4)};

        @media only screen and (min-width: 600px) {
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            grid-auto-rows: minmax(min-content, max-content);             
        }
    `
);

const StyledFormB = styled("form")(
    ({ theme }) =>
        css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 1fr);
        padding: ${theme.spacing(3)};
        padding-left: 5%;
        padding-right: 5%;
        gap: ${theme.spacing(2)};
    `
);

const StyledDiv = styled("div")(
    ({ theme }) =>
        css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 1fr);
        gap: ${theme.spacing(1)};
        padding: 0px ${theme.spacing(2)};

        @media only screen and (min-width: 600px) {
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            grid-auto-rows: minmax(min-content, max-content);             
        }
    `
);

const StyledGenderControl = styled(FormControl)(
    ({ theme }) =>
        css`
        .MuiFormLabel-root {
            font: ${theme.typography.subtitle1};
            text-align: start;
            line-height: 1;
        }

        .MuiFormGroup-root {
            display: flex;
            flex-direction: row;
            .MuiFormControlLabel-label {
                color: ${theme.palette.text.primary};
            }
        }
    `
);

const StyledHeader = styled(DialogTitle)(
    ({ theme }) =>
        css`

        border-bottom: 1px solid ${theme.palette.divider};
        margin: ${theme.spacing(1)};
    `
);

const StyledActions = styled(DialogActions)(
    ({ theme }) =>
        css`
        display: grid;
        grid-template-columns: 1fr;

        border-top: 1px solid ${theme.palette.divider};
        margin: ${theme.spacing(1)};

        > form:nth-of-type(1){
            padding: ${theme.spacing(1)} ${theme.spacing(2)};
            display: grid;
            grid-template-columns: 1fr;
            gap: ${theme.spacing(1)};

            .MuiFormControlLabel-label{
                font: ${theme.typography.caption}
            }
        }

        > div:nth-of-type(1){
            display: grid;
            grid-template-columns: 1fr auto;
            justify-items: flex-end;
            gap: ${theme.spacing(2)};            
        }
    `
);

const defaultConditions = [
    { name: "Diabetes", caption: "Diabetes" },
    { name: "HTN", caption: "High Blood Pressure" },
    { name: "Heart Disease", caption: "Heart Disease" },
    { name: "Head/Neck Cancer/Radiation", caption: "Head/Neck Cancer/Radiation" },
    { name: "Bleeding Disorders", caption: "Bleeding Disorders" },
    { name: "Epilepsy", caption: "Epilepsy" },
    { name: "Organ Transplant", caption: "Organ Transplant" },
    { name: "Other", caption: "Other" },
]


const toTitleCase = (str) => str.replace(/\b\S/g, t => t.toUpperCase());

export const Questionnaire = (props) => {

    const { state, setState } = props;

    const { clinic, page, consent } = state

    const consentContent = consent ? consent.Content : ''

    const settings = clinic.settings ? JSON.parse(clinic.settings) : {}

    const config = settings.CustomerFields ? JSON.parse(settings.CustomerFields) : []

    const mxHxConfig = settings.MedicalHxFields ? JSON.parse(settings.MedicalHxFields) : defaultConditions

    const [mxOptions, setMxOptions] = useState(defaultConditions);


    const [consentOpen, setConsentOpen] = useState(false);

    const [consentChecked, setConsentChecked] = useState(false);
    const [mergedConsent, setMergedConsent] = useState("");
    const [consentingName, setConsentingName] = useState("");

    const [firstName, setFirstName] = useState("");
    const [otherNames, setOtherNames] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("Male");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [residence, setResidence] = useState("");
    const [occupation, setOccupation] = useState("");
    const [postalAddress, setPostalAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [postOffice, setPostOffice] = useState("");
    const [nextOfKin, setNextOfKin] = useState("");
    const [kinRelation, setKinRelation] = useState("");
    const [kinContact, setKinContact] = useState("");
    const [metaData, setMetaData] = useState({});
    const [otherMedicals, setOtherMedicals] = useState("");
    const [allergies, setAllergies] = useState("");
    const [hasAllergies, setHasAllergies] = useState("No");
    const [isPregnant, setIsPregnant] = useState("No");
    const [edd, setEdd] = useState("");

    const [age, setAge] = useState(0);


    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const show = page === 1;

    const transitions = useTransition(show, {
        from: { opacity: 0.5, transform: 'translate(0%, 100%)' },
        enter: { opacity: 1, transform: 'translate(0%, 0%)' },
        leave: { opacity: 0.5, transform: 'translate(0%, -100%)' },
        reverse: show,
        config: { ...config.stiff, clamp: true }
        // config: {
        //     tension: 210,
        //     friction: 20,
        //     clamp: true
        // }
    })


    const handleSubmitA = (evt) => {
        evt.preventDefault()
        handleNext()
    }

    const handleSubmitB = (evt) => {
        evt.preventDefault()
        handleNext()
    }


    const handleSubmitC = (evt) => {
        evt.preventDefault()

        if(consent){
            setMergedConsent(
                consentContent
                    .replaceAll('‹‹$FirstName››', firstName)
                    .replaceAll('‹‹$OtherNames››', otherNames)
                    .replaceAll('‹‹$ClinicName››', clinic.clinicName)   
            )
            setConsentOpen(true)
            consentChecked(false)
        }else{            
            submitQuestionnaire(evt)
        }
        
    }

    const submitQuestionnaire  = (evt) => {
        evt.preventDefault()

        setConsentOpen(false)
        const pmh = mxOptions
            .filter(item => item.checked && item.name !== 'Other')
            .map(item => item.name)
            .join(', ') + `${otherMedicals ? ', ' + otherMedicals : ``}`
        const fals = hasAllergies === "Yes" ? allergies : '';

        const consentBy  = age  < 18 ? consentingName : "Self"
        const updatedMeta = consentChecked? {...metaData, consentVersion: consent.Version, consentBy} : {...metaData}

        const result = {
            firstName, otherNames, gender, dob, phone, email, occupation,
            physicalAddress: residence,
            postalAddress, postalCode, postalLocation: postOffice,
            kinName: nextOfKin,
            kinRelation,
            kinContacts: kinContact,
            category: 'Regular',
            metaData: JSON.stringify({
                ...updatedMeta,
                conditions: pmh.split(',').map(item => item.trim()).filter(item => item),
                allergies: fals.split(',').map(item => item.trim()).filter(item => item),
                edd: isPregnant === "Yes"? edd: undefined
            })
        }

        openSubmit(result)
    }

    const handleCheckBox = (event) => {
        const checked = event.target.checked
        const val = event.target.value

        setMxOptions([...mxOptions].map(option => option.name === val ? { ...option, checked } : option))
    };

    const openSubmit = (result) => {
        setState({ ...state, page: 2, result })
    }
    

    useEffect(() => {
        const tdob = dayjs(dob);
        const now = dayjs(new Date())

        if (tdob.isValid()) {
            setAge(now.diff(tdob, 'years'))
        } else {
            setAge(0)
        }
    }, [dob])

    useEffect(() => {
        if(page === 1)setMxOptions(mxHxConfig)
    }, [page])

    return transitions(
        (styles, item) => item &&
            <StyledRoot style={styles}>
                <AppBar position="sticky" elevation={0} >
                    <Toolbar><Typography>Customer Registration Form</Typography></Toolbar>
                </AppBar>

                <StyledStepper activeStep={activeStep} orientation="vertical"  >
                    <Step>
                        <StepLabel>Basic Info</StepLabel>
                        <StepContent>

                            <StyledForm id="formA" autoComplete="off" onSubmit={handleSubmitA}>

                                {/* <Typography>{age + ' Years'}</Typography> */}


                                {
                                    config.filter(item=>!item.hidden).map(({ fieldName, type, label, required, options, condition }, index) => {
                                        if (fieldName === 'FirstName') {
                                            return <TextField
                                                key={index}
                                                required={Boolean(required)}
                                                label={"First Name"}
                                                value={firstName}
                                                onChange={(e) => setFirstName(toTitleCase(e.target.value))}
                                                onBlur={(e) => {
                                                    const val = toTitleCase(e.target.value).replace(/\s\s+/g, ' ')
                                                    const parts = val.split(' ')

                                                    if (parts.length > 1) {
                                                        setFirstName(parts.shift())

                                                        if (!Boolean(otherNames)) {
                                                            setOtherNames(parts.join(' '))
                                                        }
                                                    }


                                                }}
                                            />
                                        } else if (fieldName === 'OtherNames') {
                                            return <TextField
                                                key={index}
                                                required={Boolean(required)}
                                                label="Other Names"
                                                value={otherNames}
                                                onChange={(e) => setOtherNames(toTitleCase(e.target.value))}
                                            />
                                        }
                                        else if (fieldName === 'DoB') {
                                            return <DatePicker
                                                key={index}
                                                required={true}
                                                label="Date of Birth (YYYY-MM-DD)" s
                                                value={dob}
                                                onChange={newDate => setDob(newDate)}
                                                maxDate={new Date()}
                                                minDate={new Date(1900, 0, 1)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                        else if (fieldName === 'Gender') {
                                            return <StyledGenderControl component="fieldset" key={index}>
                                                <FormLabel component="legend">Gender</FormLabel>
                                                <RadioGroup aria-label="gender" name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                </RadioGroup>
                                            </StyledGenderControl>
                                        } else if (fieldName === 'PhysicalAddress') {
                                            return <TextField
                                                key={index}
                                                required={Boolean(required)}
                                                label="Physical Address"
                                                value={residence}
                                                onChange={(e) => setResidence(toTitleCase(e.target.value))}
                                            />
                                        } else if (fieldName === 'Occupation') {
                                            return <TextField
                                                key={index}
                                                required={Boolean(required)}
                                                label="Occupation"
                                                value={occupation}
                                                onChange={(e) => setOccupation(toTitleCase(e.target.value))}
                                            />
                                        } else if (fieldName === 'Phone') {
                                            return <TextField
                                                key={index}
                                                required={Boolean(required)}
                                                label="Phone No"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        } else if (fieldName === 'Email') {
                                            return <TextField
                                                key={index}
                                                label="Email Address"
                                                type="email"
                                                required={Boolean(required)}
                                                value={email ? email : ""}
                                                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                            />
                                        } else if (fieldName === 'PostalAddress') {
                                            return <TextField
                                                key={index}
                                                label="P.O. Box"
                                                required={Boolean(required)}
                                                type="number"
                                                value={postalAddress}
                                                onChange={(e) => setPostalAddress(e.target.value)}
                                            />
                                        } else if (fieldName === 'PostalCode') {
                                            return <TextField
                                                key={index}
                                                label="Postal Code"
                                                required={Boolean(required)}
                                                type="number"
                                                value={postalCode}
                                                onChange={(e) => setPostalCode(e.target.value)}
                                            />
                                        } else if (fieldName === 'PostalLocation') {
                                            return <TextField
                                                key={index}
                                                label="Postal Location/Town"
                                                required={Boolean(required)}
                                                value={postOffice}
                                                onChange={(e) => setPostOffice(e.target.value.toUpperCase())}
                                            />
                                        } else {

                                            if (condition && metaData[condition.fieldName] !== condition.value) {

                                                return null
                                            }

                                            if (type === 'text') {
                                                return <TextField
                                                    key={index}
                                                    label={label}
                                                    required={Boolean(required)}
                                                    type="text"
                                                    value={metaData[fieldName] ? metaData[fieldName] : ''}
                                                    onChange={(e) => {
                                                        const clone = { ...metaData }
                                                        clone[fieldName] = e.target.value
                                                        setMetaData(clone)
                                                    }}
                                                />
                                            } else if (type === 'select') {
                                                return <FormControl fullWidth key={index}>
                                                    <InputLabel>{`${label} ${required ? ' *' : ''}`}</InputLabel>
                                                    <Select
                                                        required={Boolean(required)}
                                                        value={metaData[fieldName] ? metaData[fieldName] : ''}
                                                        label={label}
                                                        onChange={(e) => {
                                                            const clone = { ...metaData }
                                                            clone[fieldName] = e.target.value
                                                            setMetaData(clone)
                                                        }}
                                                    >
                                                        <MenuItem disabled value=''>-Select-</MenuItem>
                                                        {options.map((option) => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            }
                                        }

                                    })
                                }


                            </StyledForm>

                            <Box sx={{ gap: 2, padding: 1 }}>
                                <Button size='small' variant="contained" disableElevation type="submit" form="formA" >
                                    Continue
                                </Button>
                            </Box>
                        </StepContent>

                    </Step>

                    <Step>
                        <StepLabel>Medical Info</StepLabel>
                        <StepContent>
                            <StyledFormB id="formB" autoComplete="off" onSubmit={handleSubmitB}>

                                <Typography>Do have history any of the conditions below (select all that apply)</Typography>
                                <StyledDiv>
                                    {
                                        mxOptions.map(({ name, caption, checked }) => (
                                            <FormControlLabel
                                                key={name}
                                                control={<Checkbox checked={Boolean(checked)} onChange={handleCheckBox} />}
                                                label={caption}
                                                value={name}
                                            />
                                        ))
                                    }
                                </StyledDiv>
                                {
                                    Boolean(mxOptions[mxOptions.length - 1]['checked']) &&
                                    <>
                                        <Typography>Specify the other medical conditions in the field below</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            multiline
                                            placeholder="Other Conditions"
                                            value={otherMedicals}
                                            onChange={(e) => setOtherMedicals(e.target.value)}
                                        />
                                    </>
                                }
                                <Typography>Do have any known drug allergies</Typography>
                                <StyledDiv>
                                    <RadioGroup row name="hasAllergies" value={hasAllergies} onChange={(e) => setHasAllergies(e.target.value)}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </StyledDiv>
                                {
                                    hasAllergies === "Yes" &&
                                    <>
                                        <Typography>Specify the allergies field below</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            multiline
                                            placeholder="Allergies"
                                            value={allergies}
                                            onChange={(e) => setAllergies(e.target.value)}
                                        />
                                    </>
                                }

                                {
                                    (gender === ('Female') && age >= 18 && age <= 45) &&
                                    <>
                                        <Typography>Are you currently pregnant?</Typography>
                                        <StyledDiv>
                                            <RadioGroup row name="pregnant" value={isPregnant} onChange={(e) => setIsPregnant(e.target.value)}>
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </StyledDiv>

                                        {
                                            isPregnant === "Yes" &&
                                            <DatePicker
                                                required={true}
                                                label="Expected Delivery Date (YYYY-MM-DD)"
                                                value={edd}
                                                onChange={newDate => setEdd(newDate)}
                                                maxDate={new Date(new Date().getTime() + 40 * 7 * 24 * 60 * 60 * 1000)}
                                                minDate={new Date()}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        }
                                    </>
                                }




                            </StyledFormB>


                            <Box sx={{ gap: 2, padding: 1 }}>
                                <Button size='small' variant="contained" disableElevation type="submit" form="formB">
                                    Continue
                                </Button>
                                <Button size='small' onClick={handleBack}>
                                    Back
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>

                    <Step>
                        <StepLabel>Next of Kin Details</StepLabel>
                        <StepContent>
                            <StyledForm id="formC" autoComplete="off" onSubmit={handleSubmitC}>
                                {
                                    config.map(({ fieldName, type, label, required, options }, index) => {
                                        if (fieldName === 'KinName') {
                                            return <TextField
                                                key={index}
                                                label="Next of Kin Name"
                                                required={Boolean(required)}
                                                value={nextOfKin}
                                                onChange={(e) => setNextOfKin(toTitleCase(e.target.value))}
                                            />
                                        } else if (fieldName === 'KinRelation') {
                                            return <TextField
                                                key={index}
                                                label="Kin Relation eg. Wife"
                                                required={Boolean(required)}
                                                value={kinRelation}
                                                onChange={(e) => setKinRelation(toTitleCase(e.target.value))}
                                            />
                                        } else if (fieldName === 'KinContacts') {
                                            return <TextField
                                                key={index}
                                                label="Kin Contact"
                                                required={Boolean(required)}
                                                type="tel"
                                                value={kinContact}
                                                onChange={(e) => setKinContact(e.target.value)}
                                            />
                                        }

                                    })
                                }


                            </StyledForm>
                            <Box sx={{ gap: 2, padding: 1 }}>
                                <Button size='small' variant="contained" disableElevation type="submit" form="formC">
                                    Finish
                                </Button>
                                <Button size='small' onClick={handleBack}>
                                    Back
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                </StyledStepper>

                <Dialog
                    open={consentOpen}
                    onClose={()=>setConsentOpen(false)}
                    scroll='paper'
                >
                    <StyledHeader>Registration Terms and Conditions</StyledHeader>
                    <DialogContent>
                        <DialogContentText
                            tabIndex={-1}
                        >
                            <code>
                                {parse(mergedConsent)}
                            </code>                            
                            
                        </DialogContentText>
                    </DialogContent>
                    <StyledActions>
                        <form onSubmit={submitQuestionnaire} id="formA">
                            {
                                age < 18 &&
                                <>
                                    <Typography>
                                        Enter full name of the consenting adult guardian
                                    </Typography>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Full Name"
                                        value={consentingName}
                                        onChange={(e) => setConsentingName(toTitleCase(e.target.value))}
                                    />
                                </>
                                
                            }

                                        

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={consentChecked}
                                        onChange={event=>setConsentChecked(event.target.checked)} 
                                        required                                   
                                    />
                                }
                                label={
                                   parse(
                                    age < 18 ?
                                    `I, <strong><u>${consentingName}</u></strong>, on behalf of <strong><u>${firstName} ${otherNames}</u></strong>, agree to registration the terms and conditions above`:
                                    "I agree to registration the terms and conditions above"
                                   )
                                }
                            />
                        
                        </form>

                        <div>
                            <Button onClick={()=>setConsentOpen(false)}>Cancel</Button>
                            <Button type='submit' form="formA">Submit</Button>
                        </div>                        
                    </StyledActions>

                </Dialog>
            </StyledRoot>
    )




}